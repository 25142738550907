<template src="./fxrate.html">    
</template>
<style scoped src="./fxrate.css">
</style>
<script>
import FxConstants from '@/constants/FxConstants'
import AccountConstant from '@/constants/AccountConstants'
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import FxService from '@/services/FXServices'
import { required, requiredIf } from 'vuelidate/lib/validators'
import AppConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    data () {
        return {
            destCurrency : '',
            destCurrencyList : [] ,
            provider : '',
            providerList : FxConstants.fxProvider,
            loaderVisible : false,
            columns: ['SourceCurrency', 'DestCurrency', 'Provider', 'BuyRate', 'Markup', 'SellRate','SellRateInvers', 'Action'],
            options: {
                headings: {
                    SourceCurrency : 'Source Currency',
                    DestCurrency   : 'Destination Currency',
                    BuyRate        : 'Buy Rate',
                    SellRateInvers : 'SellRate (Inverse)',
                }
            },
            items: [],
            isAutoUpdate : false,
            isNotEditable : false,
            buyRate : '',
            markup : '',
            sellRate : '',
            action : 'Add',
            opDestCurrency : '',
            errorMessage : AppConstants.formsMessages,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            enableLazyLoading: AppConstants.enableLazyLoading,
            filteredFXAccountData: []
        }
    },
    methods : {
        ...mapActions(['fetchFxAccount','fetchFxRate']),
        async onAddFxRate() {
            this.$v.destCurrency.$touch()
            this.$v.provider.$touch()
            this.$v.buyRate.$touch()
            this.$v.markup.$touch()
            if(this.$v.destCurrency.$invalid || this.$v.provider.$invalid || this.$v.buyRate.$invalid || this.$v.markup.$invalid) {
                return
            }
            this.loaderVisible = true
            await FxService.methods.UpdateFxRate(this.destCurrency, this.buyRate, this.markup, this.provider)
            .then(async res => {
                this.loaderVisible = false
                this.showAlert(1, 'FX '+ this.action +'ed Successfully')
                await this.fetchFxRate()
                this.onSearch()
            })
            .catch(err => {
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        async onDestCurrencyChange() {
            this.loaderVisible = true
            if (!this.getFxAccountDetails) {
                await this.fetchFxAccount()
            }
            if (this.destCurrency && this.provider) {
                let provider = this.providerList.find(s=> s.value === this.provider)
                let fxAccountDetails = this.getFxAccountDetails.find(s=> s.FXProviderName === provider.text && s.Currency === this.destCurrency)
                this.isAutoUpdate = false
                this.isNotEditable = false 
                if(fxAccountDetails) {                  
                    if (fxAccountDetails.AutomatedFXRateUpdatingEnabled === 'True') {
                        this.isAutoUpdate = true
                        this.isNotEditable = true
                        this.buyRate = null
                    } else {
                        this.isAutoUpdate = false
                        this.isNotEditable = false
                    }
                }
            }
            this.loaderVisible = false
        },
        onAutoUpdateCheckChange () {
            if (this.isAutoUpdate) {
                this.isNotEditable = true
            } else {
                this.isNotEditable = false
            }          
        },
        allowOnlyNumber($event) {
             if (this.buyRate && this.markup) {
                 this.calculateSellRate()               
            }           
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46) && keyCode !== 190) {
                $event.target.value =  $event.target.value.substring(0,  $event.target.value.length - 1)
            }           
            if ($event.target.value !== null && $event.target.value.indexOf('.') > -1  && ($event.target.value.split('.')[1].length > 6)) {
                $event.target.value =  $event.target.value.substring(0,  $event.target.value.length - 1)
            }
            
        },
        calculateSellRate() {            
            if (parseFloat(this.buyRate) !== 0 && parseFloat(this.markup) !== 0) {
                this.sellRate = (parseFloat(this.buyRate) + (parseFloat(this.buyRate) * parseFloat(this.markup))).toFixed(6)
            } else if (parseFloat(this.buyRate) !== 0) {
                this.sellRate = this.buyRate
            }        
        },       
        onClear() {
            this.markup = ''
            this.buyRate = ''
            this.SourceCurrency =''
            this.action = 'Add'
            this.destCurrency = ''
            this.provider = ''
            this.sellRate = ''
            this.isNotEditable = false
            this.isAutoUpdate = false

        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        onSearch () {    
            this.filteredFXAccountData = this.opDestCurrency ? this.getRatesDetails.filter(s=> s.DestCurrency === this.opDestCurrency) : this.getRatesDetails 
            if(this.enableLazyLoading) {
                this.$refs.paginationRef.selectedPage = 1  
            }
        },
        onFxRatesDetailsEdit (destCurrency, provider, buyRate, markup) {
            this.destCurrency = destCurrency
            this.buyRate = buyRate
            this.markup = markup
            let providerList  = this.providerList.find(s=> s.text === provider)
            this.provider = providerList.value
            this.calculateSellRate()
            this.onDestCurrencyChange()
            this.action = 'Update'
        },
        onMarkupChange ($event) {
            if (this.buyRate) {
                if ($event.target.value >= 0 && $event.target.value <= 1 ) {
                    this.calculateSellRate()
                } else {
                    $event.target.value =  $event.target.value.substring(0,  $event.target.value.length - 1)
                }
            } else {
                if ($event.target.value < 0 || $event.target.value > 1 ) {
                    this.markup = null
                    this.showAlert(null, 'Markup should be between 0 to 1')
                }
            }               
        },
        generateFxRateTableData(fxTableData) {
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.value) ? currentValue?.text : accumulator
            }, items = []
            fxTableData.map( s=> {
                items.push({
                    'SourceCurrency' : s.SourceCurrency,
                    'DestCurrency'   : s.DestCurrency,
                    'Provider'       : this.providerList.reduce(reducer, s.Provider),
                    'BuyRate'        : s.BuyRate,
                    'Markup'         : s.Markup,
                    'SellRate'       : s.SellRate,
                    'SellRateInvers' : ((1/parseFloat(s.SellRate)).toFixed(2)) + ' ' + s.DestCurrency    
                })
            })
            return items
        }
    },
    validations : {
        provider : { required },
        destCurrency : { required },
        buyRate :  {
             required: requiredIf(function () {
                return !this.isAutoUpdate
             }) },
        markup : { required }
    },
    computed : {
        ...mapGetters(['getFxAccountDetails','getRatesDetails']),
        fxRateTableData() {
            let fxRateTableData = []
            if(this.getRatesDetails) {
                fxRateTableData = this.filteredFXAccountData
                if(this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    fxRateTableData = fxRateTableData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                fxRateTableData = this.generateFxRateTableData(fxRateTableData)
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.filteredFXAccountData?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return fxRateTableData
        }
    },
    components : {
        Loading, modal, pagination
    },
    async created () {
        this.loaderVisible = true
        this.destCurrencyList.push({
            text: '-- Destination Currency --',
            value : ''                        
        })
        for (const [key, value] of Object.entries([...new Set(AccountConstant.countryList.map(item => item.Currency))])) {
            this.destCurrencyList.push({
                text: value,
                value : value
            })  
        }
        await this.fetchFxRate()
        this.filteredFXAccountData = this.getRatesDetails
       
        this.loaderVisible = false
    }
}
</script>